import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: { 
        dark: true,
        themes: {
            light: {
                secondary: '#b4b4b4',
                
                colors: {
                    background: '#eee',
                    bgTopBar: '#CC3344',
                    bgdark: '#333333',
                },
            },
            dark: {
                secondary: '#b4b4b4',

                colors: {
                    background: '#eeeeee',
                    bgTopBar: '#CC3344',
                    bgdark: '#333333',
                },
            }
        }
    }
});
