<template>
  <div class="home">
    <h1>This is Home page.</h1>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style>
.home {
  
  
}
.home h1 {
  font-size: 22px;
}
</style>
