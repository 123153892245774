<template>
  <v-app>
    <v-main>
      <v-container style="max-width: 1264px">
        <TopHeader />

        <v-container class="content">
          <router-view />
        </v-container>

        <Footer />

      </v-container>
      
    </v-main>
  </v-app>
</template>

<script>
import TopHeader from './components/TopHeader.vue';
import Footer from './components/Footer.vue';
export default {
    name: "App",
    data: () => ({
    //
    }),
    components: { TopHeader, Footer }
};
</script>

<style scoped>
.v-application {
  background-color: #fff;
  background: url("@/assets/bg/BG-01.webp") center fixed;
  background-size: cover;
  width: 100%;
  font-family: "Kanit", sans-serif;
  font-size: 13px;
  color: #b4b4b4;
}

.content {
  background-color: #222;
  min-height: 600px;
  padding: 30px 80px 30px 80px;
}
</style>
